import { enableScrollLock, disableScrollLock } from '../utils/scroll';

export let emergencyOverlay;

const openClass = 'emergency-overlay--open';

function clickOutside(e) {
    const emergencyOverlayInner = emergencyOverlay.querySelector('.emergency-overlay__inner');

    if (emergencyOverlay.classList.contains(openClass) && !emergencyOverlayInner?.contains(e.target)) {
        disableScrollLock();
        emergencyOverlay.classList.remove(openClass);
    }
}

export function setupEmergencyOverlay(selector = '.emergency-overlay') {
    emergencyOverlay = document.body.querySelector(selector);

    if (emergencyOverlay) {
        enableScrollLock();
        document.body.addEventListener('click', clickOutside);
    }
}
