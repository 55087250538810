import settings from '../../settings.json';

export const breakpoints = settings.breakpoints;
export const breakpointKeys = Object.keys(breakpoints);
export let currentWindowWidth = window.innerWidth;
export let currentWindowHeight = window.innerHeight;
export let currentBreakpoint;
export let currentBreakpointIndex = 0;
let resizeTimer;

const resizeFunctions = [];

function getWindowSizes() {
    currentWindowWidth = window.innerWidth;
    currentWindowHeight = window.innerHeight;

    let lastFoundWidth = 0;

    breakpointKeys.forEach((key, index) => {
        const width = breakpoints[key];
        if (currentWindowWidth >= width && width > lastFoundWidth) {
            lastFoundWidth = width;
            currentBreakpoint = key;
            currentBreakpointIndex = index;
        }
    });
}

function resizeHandler() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
        getWindowSizes();
        resizeFunctions.forEach(funcRef => funcRef());
    }, 100);
}

export function onWindowResize(handler) {
    if (!currentBreakpoint) {
        initWindowResize();
    }

    resizeFunctions.push(handler);
}

export function initWindowResize() {
    getWindowSizes();
    window.addEventListener('resize', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);
}
