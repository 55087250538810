<template>
    <template v-if="name == 'search'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.3 16.31">
            <path d="M16,14.64l-3.12-3.13c2.4-3.15,1.79-7.65-1.37-10.05S3.86-.32,1.46,2.83C-.93,5.98-.32,10.48,2.83,12.88c2.56,1.95,6.12,1.95,8.68,0l3.12,3.12c.36,.39,.97,.41,1.36,.05,.39-.36,.41-.97,.05-1.36-.02-.02-.03-.03-.05-.05h0ZM3.49,10.89c-2.04-2.04-2.04-5.35,0-7.39,2.04-2.04,5.35-2.04,7.39,0,2.04,2.04,2.04,5.35,0,7.39-.98,.98-2.31,1.53-3.69,1.53-1.39,0-2.72-.55-3.7-1.53Z" />
        </svg>
    </template>
    <template v-else-if="name == 'plus'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
            <path d="M6.75,13.5c-.41,0-.75-.34-.75-.75v-5.25H.75c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h5.25V.75c0-.41.34-.75.75-.75s.75.34.75.75v5.25h5.25c.41,0,.75.34.75.75s-.34.75-.75.75h-5.25v5.25c0,.41-.34.75-.75.75Z" />
        </svg>
    </template>
</template>

<script>
export default {
    name: 'SvgInline',
    props: {
        name: {
            type: String,
            default: ''
        }
    }
};
</script>
