<template>
    <div class="employee-list__content">
        <template v-if="!employeeIds && (locations.length || educations.length || departments.length)">
            <div class="filter__overlay" :class="{ 'filter__overlay--open': filterIsOpen }">
                <div ref="filterOverlay" class="filter__overlay-inner">
                    <button class="filter__overlay-close" :aria-label="$t('Dictionary.Employees.CloseFilter')" @click="hideFilterModal">
                        <span class="filter__overlay-close-icon"></span>
                    </button>
                    <span class="filter__headline">{{ $t('Dictionary.Employees.FilterHeadline') }}</span>
                    <div class="filter__content">
                        <div class="filter__row">
                            <div v-if="locations.length" class="filter__col">
                                <span class="filter__subheading">{{ $t('Dictionary.Employees.FilterLocations') }}</span>
                                <div class="filter__checkbox-list">
                                    <div v-for="locationItem in locations" :key="locationItem.id" class="filter__checkbox-wrap">
                                        <input :id="'location-' + locationItem.key" v-model="selectedLocations" class="filter__checkbox" type="checkbox" :value="locationItem.key" />
                                        <label :for="'location-' + locationItem.key" class="filter__checkbox-label">{{ locationItem.name }}</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="educations.length" class="filter__col">
                                <span class="filter__subheading">{{ $t('Dictionary.Employees.FilterEducations') }}</span>
                                <div class="filter__checkbox-list">
                                    <div v-for="educationItem in educations" :key="educationItem.id" class="filter__checkbox-wrap">
                                        <input :id="'education-' + educationItem.key" v-model="selectedEducations" class="filter__checkbox" type="checkbox" :value="educationItem.key" />
                                        <label :for="'education-' + educationItem.key" class="filter__checkbox-label">{{ educationItem.name }}</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="departments.length" class="filter__col">
                                <span class="filter__subheading">{{ $t('Dictionary.Employees.FilterDepartments') }}</span>
                                <div class="filter__checkbox-list">
                                    <div v-for="departmentItem in departments" :key="departmentItem.id" class="filter__checkbox-wrap">
                                        <input :id="'department-' + departmentItem.key" v-model="selectedDepartments" class="filter__checkbox" type="checkbox" :value="departmentItem.key" />
                                        <label :for="'department-' + departmentItem.key" class="filter__checkbox-label">{{ departmentItem.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="filter__actions">
                        <button type="button" class="filter__submit btn" :aria-label="$t('Dictionary.Employees.SubmitFilter')" @click="submitFilter">
                            {{ $t('Dictionary.Employees.SubmitFilter') }}
                        </button>
                        <button type="button" class="filter__clear" :aria-label="$t('Dictionary.Employees.ClearFilter')" @click="resetFilter">
                            {{ $t('Dictionary.Employees.ClearFilter') }}
                        </button>
                    </div>
                </div>
            </div>
            <button ref="filterToggle" type="button" class="filter__toggle-overlay btn" :aria-label="$t('Dictionary.Employees.FilterToggle')" @click="showFilterModal">
                {{ $t('Dictionary.Employees.FilterToggle') }}
                <span class="btn__icon">
                    <svg-inline name="plus" />
                </span>
            </button>
        </template>
        <div class="employee-list__content-inner">
            <div v-if="filteredEmployees.length" class="employee-list__employee-list">
                <div v-for="employeeItem in filteredEmployees" :key="employeeItem.id" class="employee-list__employee">
                    <picture v-if="Object.keys(employeeItem.imageUrls).length > 0" class="employee-list__employee-image-wrap">
                        <source :srcset="employeeItem.imageUrls.xxl" media="(min-width: 1920px)" />
                        <source :srcset="employeeItem.imageUrls.md" media="(min-width: 992px)" />
                        <source :srcset="employeeItem.imageUrls.xs" media="(min-width: 480px)" />
                        <img class="employee-list__employee-image" loading="lazy" :src="employeeItem.imageUrls.default" :alt="employeeItem.imageAltText" />
                    </picture>
                    <div v-else class="employee-list__employee-image-wrap"></div>
                    <div class="employee-list__employee-text">
                        <div class="employee-list__employee-row">
                            <span class="employee-list__employee-name">{{ employeeItem.name }}</span>
                            <span v-if="employeeItem.title" class="employee-list__employee-title">{{ employeeItem.title }}</span>
                        </div>
                        <div v-if="employeeItem.phone" class="employee-list__employee-row">
                            <span class="employee-list__employee-subheading">{{ $t('Dictionary.Employees.Phone') }}</span>
                            <template v-for="(phoneItem, index) in employeeItem.phone" :key="index">
                                <a :href="'tel:'+cleanedPhone(phoneItem)" class="employee-list__employee-link">{{ phoneItem }}</a>
                                <br v-if="index != Object.keys(employeeItem.phone).length - 1" />
                            </template>
                        </div>
                        <div v-if="employeeItem.email" class="employee-list__employee-row">
                            <span class="employee-list__employee-subheading">{{ $t('Dictionary.Employees.Mail') }}</span>
                            <a :href="'mailto:'+employeeItem.email" class="employee-list__employee-link">{{ employeeItem.email }}</a>
                        </div>
                        <a v-if="employeeItem.bookingLink" :href="employeeItem.bookingLink.url" :title="$t('Dictionary.Employees.Book')" :target="employeeItem.bookingLink.target" class="employee-list__employee-book-btn btn">{{ $t('Dictionary.Employees.Book') }}</a>
                    </div>
                </div>
            </div>
            <p v-if="!filteredEmployees.length && !isLoading" class="employee-list__employee-list">
                {{ $t('Dictionary.Employees.NoResults') }}
            </p>
            <div class="spinner-wrap" :aria-hidden="isLoading ? 'false' : 'true'">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
import apiService from '../../api';
import SvgInline from '../components/svg-inline.vue';
import { enableScrollLock, disableScrollLock } from '../../utils/scroll';

export default {
    name: 'EmployeeList',
    components: {
        SvgInline
    },
    props: {
        employeeIds: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            filterIsOpen: false,
            employees: [],
            locations: [],
            educations: [],
            departments: [],
            storedLocations: [],
            storedEducations: [],
            storedDepartments: [],
            selectedLocations: [],
            selectedEducations: [],
            selectedDepartments: []
        };
    },
    computed: {
        filteredEmployees() {
            const vm = this;
            const filteredEmployees = vm.employees
                // Locations
                .filter(employeeItem => vm.storedLocations.every(x => employeeItem.locations.map(y => y.key).includes(x)))
                // Educations
                .filter(employeeItem => vm.storedEducations.every(x => employeeItem.educations.map(y => y.key).includes(x)))
                // Departments
                .filter(employeeItem => vm.storedDepartments.every(x => employeeItem.departments.map(y => y.key).includes(x)));

            return filteredEmployees;
        }
    },
    created() {
        document.body.addEventListener('click', this.clickOutside);
        this.getEmployees();
    },
    methods: {
        getEmployees() {
            const vm = this;
            vm.isLoading = true;

            apiService.employees.getEmployees(vm.employeeIds)
                .then(response => {
                    vm.employees = response.data.employees;
                    vm.locations = response.data.locations;
                    vm.educations = response.data.educations;
                    vm.departments = response.data.departments;
                })
                .catch(errors => {
                    console.log(`Error (getEmployees): ${errors}`);
                })
                .then(function () {
                    vm.isLoading = false;
                });
        },
        cleanedPhone(phone) {
            return phone.replaceAll(' ', '').trim();
        },
        hideFilterModal() {
            if (this.filterIsOpen) {
                disableScrollLock();
                this.filterIsOpen = false;
            }
        },
        showFilterModal() {
            if (!this.filterIsOpen) {
                enableScrollLock();
                this.filterIsOpen = true;
            }
        },
        clickOutside(event) {
            const filterToggle = this.$refs.filterToggle,
                filterOverlay = this.$refs.filterOverlay;

            if (!filterToggle?.contains(event.target) && !filterOverlay?.contains(event.target)) {
                this.hideFilterModal();
            }
        },
        resetFilter() {
            this.selectedLocations = [];
            this.selectedEducations = [];
            this.selectedDepartments = [];
            this.submitFilter();
        },
        submitFilter() {
            this.storedLocations = this.selectedLocations;
            this.storedEducations = this.selectedEducations;
            this.storedDepartments = this.selectedDepartments;
            this.hideFilterModal();
        }
    }
};
</script>
