function setIframeSize(element, iframe) {
    const elementWidth = element.clientWidth;
    const elementHeight = element.clientHeight;
    const iframeHeight = elementWidth * (9 / 16);

    iframe.style.width = null;
    iframe.style.height = `${Math.ceil(iframeHeight)}px`;

    if (elementHeight > iframeHeight) {
        const iframeWidth = elementHeight * (16 / 9);

        iframe.style.height = null;
        iframe.style.width = `${Math.ceil(iframeWidth)}px`;
    }
}

export function calculateIframeSize(selector = '[data-action~="iframe-resize"]') {
    const elements = document.querySelectorAll(selector);

    if (elements) {

        elements.forEach(element => {
            const iframe = element.querySelector('iframe');

            if (iframe) {

                // Timeout is apparently needed to make sure it works in our prototype setup
                setTimeout(() => {
                    setIframeSize(element, iframe);
                }, 0);
            }
        });
    }
}
