
import Player from '@vimeo/player';

export function setupVimeoVideos(selector = '[data-action~="vimeo-video"]') {
    const videos = document.querySelectorAll(selector);

    if (videos.length) {

        videos.forEach(video => {
            const iframe = video.querySelector('iframe');

            if (iframe) {
                const player = new Player(iframe);

                player.ready().then(function() {
                    video.classList.add('video--initialized');
                })
                    .catch(function() {
                        console.log('vimeo video couldn\'t load');
                    });

                player.on('play', function() {
                    video.classList.remove('video--loading');
                    video.classList.remove('video--paused');
                    video.classList.add('video--playing');
                });

                player.on('pause', function() {
                    video.classList.remove('video--playing');
                    video.classList.add('video--paused');
                });

                player.on('ended', function() {
                    video.classList.remove('video--playing');
                });

                const playTriggers = video.hasAttribute('data-video-id') ? document.querySelectorAll(`[data-video-trigger="${video.getAttribute('data-video-id')}"`) : null;

                if (playTriggers) {
                    playTriggers.forEach(playTrigger => {
                        playTrigger.addEventListener('click', () => {
                            video.classList.add('video--loading');
                            player.play();
                        });
                    });
                }
            }
        });
    }
}
