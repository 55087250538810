import axios from 'axios';

const culture = document.documentElement.lang;

const apiService = {
    employees: {
        getEmployees(employeeIds) {
            const params = { culture };

            if (employeeIds) {
                params.employeeIds = employeeIds;
            }

            return axios.get('/Umbraco/Api/EmployeeApi/GetEmployees', { params }).then(response => response);
        }
    }
};

export default apiService;
