let YT;
let videosArray = [];
const queue = {
    content: null,
    push(fn) {
        this.content = fn;
    },
    pop() {
        this.content.call();
        this.content = null;
    }
};

function loadYouTubeApi() {
    const tag = document.createElement('script');
    tag.src = `${window.location.protocol}//www.youtube.com/iframe_api`;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onPlayerReady(event) {
    const iframe = event.target.getIframe();
    if (iframe) {
        const video = iframe.closest('[data-action~="youtube-video"]');
        if (video) {
            video.classList.add('video--initialized');
        }
    }

    if (queue.content) {
        queue.pop();
    }
}

function onPlayerError(event) {
    console.log(`youtube video threw error with code ${event.data}`);
}

function isPlayerReady(player) {
    return player && typeof player.playVideo === 'function';
}

function playVideo(player) {
    isPlayerReady(player) ? player.playVideo() : queue.push(() => {
        player.playVideo();
    });
}

window.onYouTubeIframeAPIReady = function() {
    YT = window.YT;

    videosArray.forEach(video => {
        const iframe = video.querySelector('iframe');

        if (iframe) {

            const player = new YT.Player(iframe, {
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': event => {

                        if (event.data === YT.PlayerState.PLAYING) {
                            video.classList.remove('video--loading');
                            video.classList.remove('video--paused');
                            video.classList.add('video--playing');
                        }

                        if (event.data === YT.PlayerState.PAUSED) {
                            video.classList.remove('video--playing');
                            video.classList.add('video--paused');
                        }

                        if (event.data === YT.PlayerState.ENDED) {
                            video.classList.remove('video--playing');
                        }
                    },
                    'onError': onPlayerError
                }
            });

            const playTriggers = video.hasAttribute('data-video-id') ? document.querySelectorAll(`[data-video-trigger="${video.getAttribute('data-video-id')}"`) : null;

            if (playTriggers) {
                playTriggers.forEach(playTrigger => {
                    playTrigger.addEventListener('click', () => {
                        video.classList.add('video--loading');
                        playVideo(player);
                    });
                });
            }
        }
    });
};

export function setupYoutubeVideos(selector = '[data-action~="youtube-video"]') {
    const videos = document.querySelectorAll(selector);

    if (videos.length > 0) {

        videosArray = videos;

        if (!YT) {
            loadYouTubeApi();
        }
    }
}
