import { getElementScroll } from '../utils/helpers';
import { scrollLocked, onScroll } from '../utils/scroll';

export function stickyNavigation(element, className = 'navigation--sticky', scrollInPixels = 30, goingUpClass = 'navigation--going-up') {
    let scrollTimer;
    let lastScrollPosition;

    const scrollHandler = () => {

        clearTimeout(scrollTimer);

        if (!scrollLocked) {
            scrollTimer = setTimeout(() => {
                const windowScroll = getElementScroll();

                if (windowScroll.top > scrollInPixels) {
                    element.classList.add(className);

                    if (lastScrollPosition > windowScroll.top) {
                        element.classList.add(goingUpClass);
                    } else {
                        element.classList.remove(goingUpClass);
                    }

                    lastScrollPosition = windowScroll.top;
                } else {
                    element.classList.remove(className);
                    element.classList.remove(goingUpClass);
                }
            }, 30);
        }

    };

    onScroll(scrollHandler, true);
}
