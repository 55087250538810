export let searchContainer,
    searchToggleBtn,
    searchOverlay,
    searchForm,
    searchInput;

export function showSearchOverlay() {
    document.body.classList.add('search--open');
}

export function hideSearchOverlay() {
    document.body.classList.remove('search--open');
    searchInput.blur();
    searchInput.value = '';
}

export function setupSearch() {
    searchContainer = document.body.querySelector('.navigation__search');

    if (searchContainer) {
        searchToggleBtn = searchContainer.querySelector('.search__btn--toggle');
        searchOverlay = searchContainer.querySelector('.search__overlay');
        searchForm = searchContainer.querySelector('.search__form');
        searchInput = searchContainer.querySelector('.search__input');
    }

    if (searchToggleBtn && searchOverlay && searchForm && searchInput) {

        searchToggleBtn.addEventListener('click', showSearchOverlay);
        searchOverlay.addEventListener('click', function(e) {
            if (!searchForm.contains(e.target)) {
                hideSearchOverlay();
            }
        });

        document.addEventListener('keyup', function(e) {
            if (e.key === 'Escape') {
                hideSearchOverlay();
            }
        });
    }

    if (searchInput) {
        searchInput.addEventListener('focus', () => {
            // Set the cursor to end of the input
            const val = searchInput.value;
            searchInput.value = '';
            searchInput.value = val;
        });
    }
}
